<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-row>
      <v-col cols="6">
        <div
          class="statistics-box d-flex zvt-main-info flex-wrap"
          :class="{ 'blocked-box': blocked_visibility }"
        >
          <v-col cols="5">
            <label>
              <span class="font-weight-bold"> 1 Заявление </span>
              <div class="d-flex">
                <v-text-field
                  value="ЗВТ"
                  outlined
                  hide-details="auto"
                  dense
                  disabled
                  background-color="grey lighten-2"
                  class="mr-1 p-0"
                />
                <v-autocomplete
                  ref="customs_procedure"
                  v-model="type_declaration.customs_procedure"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  :items="customsProcedures"
                  item-text="text"
                  item-value="code"
                  class="mr-1 p-0"
                />
                <v-autocomplete
                  ref="previous_customs_procedure"
                  v-model="type_declaration.previous_customs_procedure"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  :items="customsProcedures"
                  item-text="text"
                  item-value="code"
                  class="mr-1 p-0"
                />
              </div>
            </label>
          </v-col>
          <v-col cols="2" />
          <v-col cols="5">
            <label> 2б Конечная дата предоставления ДТ </label>
            <custom-date-picker
              ref="finish_date"
              v-model="type_declaration.end_date_dt"
              background-color="grey lighten-2"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
            />
          </v-col>
        </div>
      </v-col>

      <v-col cols="6">
        <div class="statistics-box d-flex flex-wrap">
          <v-col cols="4">
            <label>
              А. Регистрационный номер
              <field-menu>
                <v-list>
                  <v-list-item
                    @click="disabled = !disabled"
                  >Ручное заполнение</v-list-item>
                  <v-list-item
                    @click="fillWithCustomsData"
                  >Заполнить данными из таможни</v-list-item>
                  <v-list-item
                    @click="clearRegNumA"
                  >Очистить регистрационный номер и сменить ID документа</v-list-item>
                </v-list>
              </field-menu>
              <v-text-field
                ref="reg_num_a"
                v-model="registration_details.reg_num_a"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
                :disabled="disabled"
                outlined
                dense
                hide-details="auto"
              />
            </label>
          </v-col>
          <v-col
            cols="5"
            class="pl-0"
          >
            <label> С. Регистрационный номер выпуска </label>
            <v-text-field
              ref="reg_num_b"
              v-model="registration_details.reg_num_b"
              v-mask="'#####/########'"
              class="mr-1"
              :background-color="disabled ? 'grey lighten-2' : 'white'"
              outlined
              dense
              :disabled="disabled"
              hide-details="auto"
            />
          </v-col>
          <v-col
            cols="3"
            class="pl-0"
          >
            <label> Дата выпуска </label>
            <custom-date-picker
              ref="reg_date_b"
              v-model="registration_details.reg_date_b"
              :background-color="disabled ? 'grey lighten-2' : 'white'"
              :disabled="disabled"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
            />
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import FieldMenu from "@/components/documents/field-menu.vue";
import blockVisibility from "@/mixins/block-visibility";
import { type_declaration as onTypeDeclaration } from "@/events/statistics/control";
import { highlightField } from "@/helpers/control";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";

export default {
  components: { CustomDatePicker, FieldMenu },
  mixins: [blockAutoUpdate, blockVisibility, filterBySearchFieldMixin],
  data() {
    return {
      date: null,
      block_id: "zvt-main-info",
      disabled: true,
      type_declaration: {
        declaration_id: null,
        customs_procedure: "",
        previous_customs_procedure: "",
        end_date_dt: "",
      },
      registration_details: {
        declaration_id: null,
        reg_num_a: "",
        reg_num_b: "",
        reg_date_b: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      selected: "zvt/getSelected",
      selected_wares: "zvt/getSelectedWares",
      customsProcedures: "catalogs/getNsiCustomsProcedures",
      declarationFeatures: "catalogs/getNsiDeclarationFeatures",
    }),
    quantity() {
      return this.selected_wares?.length || 0;
    },
  },
  watch: {
    selected() {
      this.setFields();
    },
  },
  created() {
    onTypeDeclaration.subscribe(this.highlightField);
  },
  beforeDestroy() {
    onTypeDeclaration.unsubscribe();
  },
  methods: {
    highlightField,
    setFields() {
      const { type_declaration } = this.selected || {};
      const { registration_details } = this.selected || {};
      for (let key in type_declaration) {
        if (key in this.type_declaration) {
          this.type_declaration[key] = type_declaration[key];
        }
      }
      for (let key in registration_details) {
        if (key in this.registration_details) {
          this.registration_details[key] = registration_details[key];
        }
      }
      this.setHasChangesFalse();
    },
    async uploadData() {
      const type_declaration = this.convertEmptyStringsToNull(
        this.type_declaration
      );
      const registration_details = this.convertEmptyStringsToNull(
        this.registration_details
      );

      const typeDeclarationResponse = await this.$store.dispatch(
        "zvt/uploadBlockData",
        {
          name: "type_declaration",
          value: type_declaration,
        }
      );

      await this.$store.dispatch("zvt/uploadBlockData", {
        name: "registration_details",
        value: registration_details,
      });

      this.disabled = true;
      return Promise.resolve(typeDeclarationResponse);
    },
  },
};
</script>
